import React, { useState, useEffect } from 'react';
import './AddAgent.css';

const AddAgent = () => {
  const usrdetails = JSON.parse(sessionStorage.getItem("userDetails")) || {};
  const company_id = usrdetails.CompanyID || '';
  const mainagent_id = usrdetails.MainAgentID || '';
  const subagentid = usrdetails.SubAgentID || '';
  const [worldData, setWorldData] = useState({});
  const [formData, setFormData] = useState({
    companyID: company_id,
    mainagentID: mainagent_id,
    subagent_id: subagentid,
    agent_type: '',
    full_name: '',
    tax_id: '',
    agent_email: '',
    agent_phone: '',
    agent_address: '',
    agent_idnumber: '',
    exp_date: '',
    issue_date: '',
    id_type: '',
    agent_city: '',
    agent_state: '',
    agent_country: '',
    agent_limit: '',
    agent_type_m: '',
    owner_name: ''
  });

  useEffect(() => {
    setWorldData({
      USA: {
  Alabama: ["Birmingham", "Montgomery", "Mobile"],
  Alaska: ["Anchorage", "Fairbanks", "Juneau"],
  Arizona: ["Phoenix", "Tucson", "Mesa"],
  Arkansas: ["Little Rock", "Fayetteville", "Fort Smith"],
  California: ["Los Angeles", "San Diego", "Sacramento", "San Francisco", "San Jose"],
  Colorado: ["Denver", "Colorado Springs", "Aurora"],
  Connecticut: ["Hartford", "New Haven", "Stamford"],
  Delaware: ["Wilmington", "Dover", "Newark"],
  Florida: ["Miami", "Orlando", "Tampa", "Jacksonville", "Tallahassee"],
  Georgia: ["Atlanta", "Savannah", "Augusta"],
  Hawaii: ["Honolulu", "Hilo", "Kailua"],
  Idaho: ["Boise", "Meridian", "Nampa"],
  Illinois: ["Chicago", "Springfield", "Naperville"],
  Indiana: ["Indianapolis", "Fort Wayne", "Evansville"],
  Iowa: ["Des Moines", "Cedar Rapids", "Davenport"],
  Kansas: ["Wichita", "Overland Park", "Kansas City"],
  Kentucky: ["Louisville", "Lexington", "Bowling Green"],
  Louisiana: ["New Orleans", "Baton Rouge", "Shreveport"],
  Maine: ["Portland", "Augusta", "Bangor"],
  Maryland: ["Baltimore", "Annapolis", "Frederick"],
  Massachusetts: ["Boston", "Worcester", "Springfield"],
  Michigan: ["Detroit", "Grand Rapids", "Lansing"],
  Minnesota: ["Minneapolis", "Saint Paul", "Rochester"],
  Mississippi: ["Jackson", "Gulfport", "Biloxi"],
  Missouri: ["Kansas City", "Saint Louis", "Springfield"],
  Montana: ["Billings", "Missoula", "Bozeman"],
  Nebraska: ["Omaha", "Lincoln", "Bellevue"],
  Nevada: ["Las Vegas", "Reno", "Henderson"],
  "New Hampshire": ["Manchester", "Nashua", "Concord"],
  "New Jersey": ["Newark", "Jersey City", "Paterson"],
  "New Mexico": ["Albuquerque", "Santa Fe", "Las Cruces"],
  "New York": ["New York City", "Buffalo", "Rochester"],
  "North Carolina": ["Charlotte", "Raleigh", "Greensboro"],
  "North Dakota": ["Fargo", "Bismarck", "Grand Forks"],
  Ohio: ["Columbus", "Cleveland", "Cincinnati"],
  Oklahoma: ["Oklahoma City", "Tulsa", "Norman"],
  Oregon: ["Portland", "Salem", "Eugene"],
  Pennsylvania: ["Philadelphia", "Pittsburgh", "Allentown"],
  "Rhode Island": ["Providence", "Cranston", "Warwick"],
  "South Carolina": ["Charleston", "Columbia", "Greenville"],
  "South Dakota": ["Sioux Falls", "Rapid City", "Aberdeen"],
  Tennessee: ["Nashville", "Memphis", "Knoxville"],
  Texas: ["Houston", "Austin", "Dallas", "San Antonio"],
  Utah: ["Salt Lake City", "West Valley City", "Provo"],
  Vermont: ["Burlington", "Montpelier", "Rutland"],
  Virginia: ["Virginia Beach", "Norfolk", "Richmond"],
  Washington: ["Seattle", "Spokane", "Tacoma", "Bellevue", "Kent"],
  "West Virginia": ["Charleston", "Huntington", "Morgantown"],
  Wisconsin: ["Milwaukee", "Madison", "Green Bay"],
  Wyoming: ["Cheyenne", "Casper", "Laramie"],
      },
      India: {
        Maharashtra: ["Mumbai", "Pune", "Nagpur"],
        TamilNadu: ["Chennai", "Madurai"],
        Karnataka: ["Bangalore", "Mangalore"],
      },
      Canada: {
        Alberta: ["Calgary", "Edmonton", "Red Deer"],
        BritishColumbia: ["Vancouver", "Kelowna"],
        Manitoba: ["Winnipeg", "Brandon"],
      },
      Germany: {
        Bavaria: ["Munich", "Nuremberg"],
        NorthRhine: ["Cologne", "Düsseldorf"],
      },
      Ethiopia: {
        "Addis Ababa": ["Addis Ababa"],
        "Dire Dawa": ["Dire Dawa"],
        Harar: ["Harar"],
        Wajaale: ["Wajaale"],
        Jijiga: ["Jijiga"],
        Mekele: ["Mekele"],
        Gonder: ["Gonder"],
        Jima: ["Jima"],
        Asela: ["Asela"],
        Adema: ["Adema"],
        Awash: ["Awash"],
        Dese: ["Dese"],
        "Bahir Dar": ["Bahir Dar"],
        Negele: ["Negele"],
        Shashemene: ["Shashemene"],
        Sodo: ["Sodo"],
        Bedesa: ["Bedesa"],
        Amhara: ["Amhara"],
      },
      Somalia: {
        Mogadishu: ["Bakaaro", "Warta-nabada", "Dayniile", "Howlwadaag", "Hodan", "Dharkanley", "Shibis", "Daarasalaam", "Huriwaa", "Yaqshid"],
        South_West: ["Badhabo"],
        Hirshabelle: ["Jowhar", "Beledweyne"],
        Galmudug: ["Dhuusamareeb", "Adado", "Elbuur", "Galkacyo", "Galhareeri"],
        Puntland: ["Bosaso", "Qardho", "Garowe"],
        Jubba_Land: ["Kismayo"],
        Somaliland: ["Hargeisa", "Borama"],
      }
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleCountryChange = (e) => {
    const country = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      agent_country: country,
      agent_state: '',
      agent_city: ''
    }));
  };

  const handleStateChange = (e) => {
    const state = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      agent_state: state,
      agent_city: ''
    }));
  };

  const handleCityChange = (e) => {
    const city = e.target.value;
    setFormData((prevFormData) => ({
      ...prevFormData,
      agent_city: city
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = 'https://gogolow.com/backend/api/modules/createagent';

    try {
      console.log('Submitting form data:', formData);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      console.log('Fetch response:', response);
      const text = await response.text();
      console.log('Raw response text:', text);

      let data = null;
      try {
        data = JSON.parse(text);
        console.log(data);
      } catch (jsonError) {
        console.error('Error parsing JSON:', jsonError);
        alert('Error parsing server response.');
        return;
      }

      console.log('Response data:', data);

      if (data && data.status === 'error') {
        console.log('Error data:', data);
        alert(data.message);
      } else if (data && data.status === 'success') {
        console.log('Success data:', data);
        alert('Agent added successfully!');
        window.location.reload();
      } else {
        alert('Unexpected server response.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('There was an error processing your request.');
    }
  };

  return (
    <div className="container">
      <h1>Add Agent</h1>
      <form onSubmit={handleSubmit}>
        <select
          name="agent_type"
          className="full-width"
          value={formData.agent_type}
          onChange={handleChange}
          required
        >
          <option value="">Select Location Type</option>
          <option value="Agent">Agent</option>
        </select>
        <input
          type="text"
          name="full_name"
          className="full-width"
          placeholder="Person Name Or Business Name"
          value={formData.full_name}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="tax_id"
          className="half-width"
          placeholder="Tax ID"
          value={formData.tax_id}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="agent_email"
          className="full-width"
          placeholder="Email"
          value={formData.agent_email}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="agent_phone"
          className="full-width"
          placeholder="Phone Number"
          value={formData.agent_phone}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="agent_idnumber"
          className="full-width"
          placeholder="ID Number"
          value={formData.agent_idnumber}
          onChange={handleChange}
          required
        />
        <input
          type="date"
          name="issue_date"
          className="half-width"
          placeholder="Issue Date"
          value={formData.issue_date}
          onChange={handleChange}
          required
        />
        <input
          type="date"
          name="exp_date"
          className="half-width"
          placeholder="Exp. Date"
          value={formData.exp_date}
          onChange={handleChange}
          required
        />
        <select
          name="id_type"
          className="full-width"
          value={formData.id_type}
          onChange={handleChange}
          required
        >
          <option value="">Select Document Type</option>
          <option value="passport">Passport</option>
          <option value="national_id">National ID</option>
        </select>
        <input
          type="text"
          name="agent_address"
          className="full-width"
          placeholder="Address"
          value={formData.agent_address}
          onChange={handleChange}
          required
        />
        <select
          name="agent_country"
          className="full-width"
          value={formData.agent_country}
          onChange={handleCountryChange}
          required
        >
          <option value="">Select Country</option>
          {Object.keys(worldData).map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </select>
        {formData.agent_country && (
          <select
            name="agent_state"
            className="full-width"
            value={formData.agent_state}
            onChange={handleStateChange}
            required
          >
            <option value="">Select State</option>
            {Object.keys(worldData[formData.agent_country]).map((state) => (
              <option key={state} value={state}>
                {state}
              </option>
            ))}
          </select>
        )}
        {formData.agent_state && (
          <select
            name="agent_city"
            className="full-width"
            value={formData.agent_city}
            onChange={handleCityChange}
            required
          >
            <option value="">Select City</option>
            {worldData[formData.agent_country][formData.agent_state].map((city) => (
              <option key={city} value={city}>
                {city}
              </option>
            ))}
          </select>
        )}
        <input
          type="number"
          name="agent_limit"
          className="full-width"
          placeholder="Limit"
          value={formData.agent_limit}
          onChange={handleChange}
          required
        />
        <button type="submit">Add Agent</button>
      </form>
    </div>
  );
};

export default AddAgent;

