
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LimitChange = () => {
  const storedUserData = JSON.parse(sessionStorage.getItem("userDetails")) || {};

;
 // console.log(storedUserData);


  const [companyID, setCompanyID] = useState('');
  const [mainAgentID, setMainAgentID] = useState('');
  const [subAgentID, setSubAgentID] = useState('');
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState('');
  const [agenttochangeID,setAgenttochangeID] = useState('');
  const [amount, setAmount] = useState('');

  useEffect(() => {
    setCompanyID(storedUserData.CompanyID);
    setMainAgentID(storedUserData.MainAgentID);
   setSubAgentID(storedUserData.SubAgentID);
    fetchAgents();
  }, [storedUserData]);

  const fetchAgents = async () => {
    try {
      const response = await axios.post('https://gogolow.com/backend/api/modules/commissionrate', { mainagent: mainAgentID });
      const data = response.data;
      //console.log(data);
      if (data.subagents) {
        setAgents(data.subagents);
      } else {
        console.error('Failed to fetch agents', data.message);
      }
    } catch (error) {
      console.error('Error fetching agents', error);
    }
  };

  const handleAgentChange = (e) => {
    const selected = e.target.value;
    setSelectedAgent(selected);
    const agent = agents.find(agent => agent.name === selected);
     //console.log(agent);
    if (agent) {
     
      setSubAgentID(agent.ID);
      setAgenttochangeID(agent.ID);
     
    }
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (amount.length === 0) {
      alert('Commission rate is not valid!');
      return;
    }

    try {

      const response = await axios.post('https://gogolow.com/backend/api/modules/balanceupdate', {
        cmpid: companyID,
        mnaid: mainAgentID,
        sbagid: subAgentID,
        tra: amount,
        thisagent:agenttochangeID,
      });
      const data = response.data;
      console.log(agenttochangeID);
      console.log(data);
      if (data.status === 'success') {
        alert('Your limit has been changed.');
        setSelectedAgent('');
        setAmount('');
         window.location.reload();
      } else {
        
        alert(data.message);
      }
    } catch (error) {
      console.error('Error saving rate', error);
    }
  };

  return (
    <div>
      <h2>Agent Limit Change</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="agentList">Agent</label>
          <select id="agentList" value={selectedAgent} onChange={handleAgentChange}>
            <option value="">Select Agent</option>
            {agents.map(agent => (
              <option key={agent.ID} value={agent.name}>
                {agent.name} - {agent.ID}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="amountlimit">Amount</label>
          <input
            type="text"
            id="amountlimit"
            value={amount}
            onChange={handleAmountChange}
          />
        </div>
        <button type="submit">Limit Update</button>
      </form>
    </div>
  );
};

export default LimitChange;

