import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './IncomingRemittence.css';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, InputAdornment, Button, MenuItem, Select, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const IncomingRemittence = () => {
  const usrdetails = JSON.parse(sessionStorage.getItem("userDetails")) || {};
  const ComID = usrdetails.CompanyID || '';
  const MaiAgID = usrdetails.MainAgentID || '';
  const SubAgID = usrdetails.SubAgentID || '';
  const City = usrdetails.City || '';
  const State = usrdetails.State || '';
  const Country = usrdetails.Country || '';
  console.log(usrdetails);

  const [searchTerm, setSearchTerm] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [remittances, setRemittances] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedRemittance, setSelectedRemittance] = useState(null);
  const [note, setNote] = useState('');

  const formatDate = (date) => {
    return date ? date.toISOString().split('T')[0] : '';
  };

  const handleSubmit = () => {
    if (fromDate && toDate) {
      const params = {
        companyID: ComID,
        mainagent: MaiAgID,
        agent: SubAgID,
        country: Country,
        state: State,
        city: City,
        fromDate: formatDate(fromDate),
        toDate: formatDate(toDate)
      };

      // Print the params object as a JSON string in the console
      console.log(JSON.stringify(params, null, 2));

      axios.get('https://gogolow.com/backend/api/modules/incomingremittence', { params })
        .then(response => {
          console.log(response);
          // Ensure response data is in the expected format and update the state
          if (response.data && Array.isArray(response.data.Returned)) {
            setRemittances(response.data.Returned);
          } else {
            setRemittances([]);
          }
        })
        .catch(error => {
          console.error('Error fetching remittances:', error);
          setRemittances([]);
        });
    }
  };

  const handleActionChange = (event, remittance) => {
    const action = event.target.value;
    console.log(`Action: ${action} for remittance ID: ${remittance.RemittanceID}`);
    if (action === 'pay' && remittance.Status === 'pending') {
      setSelectedRemittance(remittance);
      setOpen(true);
    } else if (action === 'return') {
      // handle return remittance
    } else if (action === 'report') {
      // handle report remittance
    } else {
      alert('Only pending remittances can be paid.');
    }
  };

  const handleDialogClose = () => {
    setOpen(false);
    setNote('');
  };

  const handleCompletePayment = () => {
    const data = new FormData();
    data.append('companyID', ComID);
    data.append('mainagentID', MaiAgID);
    data.append('remitenceID', selectedRemittance.RemittanceID);
    data.append('subagent_id', SubAgID);
    data.append('message', note);
    data.append('document', selectedRemittance.servicetype);
    data.append('amount', selectedRemittance.Amount);

    axios.post('https://gogolow.com/backend/api/modules/remittancepaid', data)
      .then(response => {
        console.log(response);
        if (response.data.status === "success") {
          alert("Payment completed successfully");
          handleDialogClose();
           
          handleSubmit(); // Refresh the table to reflect the status change
        } else {
          alert(response.data.message);
          handleDialogClose();
        }
      })
      .catch(error => {
        console.error('Error completing payment:', error);
        alert("An error occurred while completing the payment");
        handleDialogClose();
      });
  };

  const filteredRemittances = remittances.filter(remittance => {
    const isWithinDateRange = (!fromDate || new Date(remittance.DateSent) >= fromDate) && (!toDate || new Date(remittance.DateSent) <= toDate);
    const matchesSearchTerm = searchTerm === '' || (
      (remittance.SenderName && remittance.SenderName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (remittance.ReceiverName && remittance.ReceiverName.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (remittance.PhoneNumber && remittance.PhoneNumber.includes(searchTerm))
    );
    const isPaidOrPending = remittance.Status.toLowerCase() === 'paid' || remittance.Status.toLowerCase() === 'pending';
    return isWithinDateRange && matchesSearchTerm && isPaidOrPending;
  });

  return (
    <div>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        <TextField
          label="Filter"
          placeholder="filter" 
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                🔍
              </InputAdornment>
            ),
          }}
          style={{ marginRight: '20px' }}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="From Date"
            value={fromDate}
            onChange={(newValue) => setFromDate(newValue)}
            renderInput={(params) => <TextField {...params} style={{ marginRight: '20px' }} />}
          />
          <DatePicker
            label="To Date"
            value={toDate}
            onChange={(newValue) => setToDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginLeft: '20px' }}>
          Submit
        </Button>
      </div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Sender</TableCell>
              <TableCell>Sender Tel</TableCell>
              <TableCell>Receiver</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Date Sent</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRemittances.map(remittance => (
              <TableRow key={remittance.RemittanceID}>
                <TableCell>{remittance.RemittanceID}</TableCell>
                <TableCell>{remittance.SenderName}</TableCell>
                <TableCell>{remittance.SenderPhone}</TableCell>
                <TableCell>{remittance.ReceiverName}</TableCell>
                <TableCell>{remittance.PhoneNumber}</TableCell>
                <TableCell>{remittance.Amount}</TableCell>
                <TableCell>{new Date(remittance.DateSent).toDateString()}</TableCell>
                <TableCell>{remittance.Status}</TableCell>
                <TableCell>
                  <Select
                    value=""
                    onChange={(event) => handleActionChange(event, remittance)}
                    displayEmpty
                    renderValue={() => "Select Action"}
                  >
                    <MenuItem value="pay">Pay</MenuItem>
                    <MenuItem value="return">Return</MenuItem>
                    <MenuItem value="report">Report</MenuItem>
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Pay Remittance</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please complete the following details to process the remittance.
          </DialogContentText>
          {selectedRemittance && (
            <>
              <TextField
                margin="dense"
                label="Receiver Name"
                type="text"
                fullWidth
                value={selectedRemittance.ReceiverName}
                disabled
              />
              <TextField
                margin="dense"
                label="Amount"
                type="text"
                fullWidth
                value={selectedRemittance.Amount}
                disabled
              />
              <TextField
                margin="dense"
                label="Phone Number"
                type="text"
                fullWidth
                value={selectedRemittance.PhoneNumber}
                disabled
              />
              <TextField
                margin="dense"
                label="Remittance ID"
                type="text"
                fullWidth
                value={selectedRemittance.RemittanceID}
                disabled
              />
              <TextField
                margin="dense"
                label="Payment Method"
                type="text"
                fullWidth
                value={selectedRemittance.servicetype}
                disabled
              />
              <TextField
                margin="dense"
                label="Note"
                type="text"
                fullWidth
                multiline
                rows={4}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleCompletePayment} color="primary">
            Complete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IncomingRemittence;
