import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import SendMoneyForm from './SendMoneyForm';
import './Receipt.css';
import logo from '../logo.ico'; 

const Receipt = ({
  date,
  receiptNumber,
  cashier,
  senderName,
  senderPhone,
  senderAddress,
  receiverName,
  receiverPhone,
  receiverAddress,
  amount,
  commission,
  tax,
  totalAmount,
  paymentMethod,
  receiverCollectionMethod,
  nonDiscriminatoryStatement,
}) => {
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const companyName = userData.name;
  const address = userData.Address;
  const phoneNumber = userData.PhoneNumber;
  const website = "www.faraspay.com";
  const Companyaddress = "6727 MLK Jr Way S Seattle WA 98118";
  const CompanyPhoneNumber = "206-414-6000";
  const receiptRef = useRef();

  const handlePrint = () => {
    const printContents = receiptRef.current.innerHTML;
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.top = '-10000px';
    document.body.appendChild(iframe);

    const doc = iframe.contentWindow.document;
    doc.open();
    doc.write(`
      <html>
        <head>
          <title>Receipt</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              padding: 20px;
              border: 1px solid #000;
              max-width: 600px;
              margin: 0 auto;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #000;
              padding: 8px;
            }
            .print-button {
              width: 80px;
              padding: 6px;
              font-size: 14px;
              cursor: pointer;
              background-color: #4CAF50;
              color: white;
              border: none;
              border-radius: 4px;
              margin-top: 20px;
            }
            .right-side-container {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: -20px;
            }
            .left-text {
              font-size: 14px;
              text-align: left;
            }
            .red-text {
              font-size: 14px;
              text-align: right;
            }
            .hr {
              border: 0;
              border-top: 1px solid #000;
              margin: 10px 0;
              height: 1px;
              background-color: #000;
            }
            @media print {
              .print-button {
                display: none;
              }
              .right-side-container {
                display: table;
                width: 100%;
              }
              .left-text, .red-text {
                display: table-cell;
                padding: 8px;
                vertical-align: top;
              }
              .left-text {
                width: 50%;
                text-align: left;
              }
              .red-text {
                width: 50%;
                text-align: right;
              }
            }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `);
    doc.close();

    iframe.onload = () => {
      setTimeout(() => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        document.body.removeChild(iframe);
      }, 100); // Adding a slight delay to ensure content is fully loaded
    };
  };

  const myreceipt = JSON.parse(sessionStorage.getItem("receiptissued")) || {};
  console.log(myreceipt);

  return (
    <div ref={receiptRef} style={{ fontFamily: 'Arial, sans-serif', padding: '20px', border: '1px solid #000', maxWidth: '600px', margin: '0 auto', position: 'relative' }}>
      <div style={{ textAlign: 'center', marginBottom: '20px' }}>
        <img src={logo} alt="Logo" style={{ width: '100px', marginBottom: '10px' }} />
        <h2>{companyName}</h2>
        <p>{Companyaddress} | {CompanyPhoneNumber} | {website}</p>
      </div>

      <h3>Receipt</h3>

      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #000', padding: '8px' }}>Sender Info</th>
            <th style={{ border: '1px solid #000', padding: '8px' }}>Receiver Info</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #000', padding: '8px' }}>
              <p><strong>Name:</strong> {myreceipt.SenderName}</p>
              <p><strong>Phone:</strong> {myreceipt.SenderPhone}</p>
              <p><strong>Address:</strong> {myreceipt.SenderAddress + " " + myreceipt.SenderCity + " " + myreceipt.SenderCountry}</p>
            </td>
            <td style={{ border: '1px solid #000', padding: '8px' }}>
              <p><strong>Name:</strong> {myreceipt.ReceiverName}</p>
              <p><strong>Phone:</strong> {myreceipt.PhoneNumber}</p>
              <p><strong>Address:</strong> {myreceipt.payingCity}</p>
            </td>
          </tr>
        </tbody>
      </table>

      <table style={{ width: '100%', borderCollapse: 'collapse', marginBottom: '20px' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #000', padding: '8px' }}>Sending Amount</th>
            <th style={{ border: '1px solid #000', padding: '8px' }}>Paying Amount</th>
            <th style={{ border: '1px solid #000', padding: '8px' }}>Fee</th>
            <th style={{ border: '1px solid #000', padding: '8px' }}>Tax</th>
            <th style={{ border: '1px solid #000', padding: '8px' }}>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ border: '1px solid #000', padding: '8px' }}>{parseFloat(myreceipt.Amount).toFixed(2)} USD</td>
            <td style={{ border: '1px solid #000', padding: '8px' }}>{parseFloat(myreceipt.Amount).toFixed(2)} USD</td>
            <td style={{ border: '1px solid #000', padding: '8px' }}>{parseFloat(myreceipt.fees).toFixed(2)} USD</td>
            <td style={{ border: '1px solid #000', padding: '8px' }}>{parseFloat(0).toFixed(2)} USD</td>
            <td style={{ border: '1px solid #000', padding: '8px' }}>{parseFloat(myreceipt.totalcollected).toFixed(2)} USD</td>
          </tr>
        </tbody>
      </table>

      <div className="right-side-container">
        <p className="left-text"><strong>Receiver Collection Method:</strong> {myreceipt.servicetype}<br />Thank you for doing business with us!</p>

        <div className="red-text">
          Exchange Rate: = $1.00 USD = 1 USD<br />
          Total Transfer: = {parseFloat(myreceipt.totalcollected).toFixed(2)} USD<br />
          Other Fees: = {parseFloat(myreceipt.fees).toFixed(2)} USD<br />
          Taxes: = 0.00 USD<br />
          <div style={{ borderTop: '1px solid black', margin: '10px 0', width: '100%' }}></div>
          Total to Recipient: {parseFloat(myreceipt.Amount).toFixed(2)} USD
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <p><strong>Disclaimer:</strong> By signing this receipt, you acknowledge that you have been provided with a copy, have reviewed, and agreed to the terms and conditions set forth above. You further acknowledge that your information is accurate, complete, and truthful.</p>
      </div>

      <div className="receipt-footer" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p><strong>Customer Signature:</strong> _______________</p>
        <p><strong>Agent Signature:</strong> _______________</p>
      </div>

      <button
        onClick={handlePrint}
        className="print-button"
        style={{
          width: '80px',
          padding: '6px',
          fontSize: '14px',
          cursor: 'pointer',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '4px',
          marginTop: '20px'
        }}>
        Print
      </button>
    </div>
  );
};



export default Receipt;
