import React, { useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    axios
      .post("https://gogolow.com/backend/api/modules/userLogin4web", {
        userEmail: email,
        userPassword: password,
      })
      .then((response) => {
        console.log(response);
        setLoading(false);
        if (response.data.status === "success") {
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.setItem(
            "userDetails",
            JSON.stringify(response.data.userDetails)
          );
          window.location.href = "/Dashboard";
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError("An error occurred. Please try again.");
      });
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          backgroundColor: "white",
          padding: "40px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <form onSubmit={handleSubmit}>
          <Typography
            sx={{ marginBottom: "20px", textAlign: "center" }}
            variant="h4"
            component="h1"
          >
            Sign in
          </Typography>
          {error && (
            <Alert sx={{ marginBottom: "20px" }} severity="error">
              {error}
            </Alert>
          )}
          <TextField
            type="email"
            value={email}
            onChange={handleEmailChange}
            variant="outlined"
            id="email"
            label="Email address"
            fullWidth
            sx={{ marginBottom: "20px" }}
          />
          <TextField
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={handlePasswordChange}
            variant="outlined"
            id="password"
            label="Password"
            fullWidth
            sx={{ marginBottom: "20px" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            fullWidth
            sx={{ marginBottom: "20px", height: "50px" }}
          >
            {loading ? <CircularProgress size={24} /> : "Login"}
          </Button>
          <Typography
            sx={{ textAlign: "center" }}
            variant="body2"
            component="p"
          >
            Don't have an account? <Link href="/register">Sign up</Link>
          </Typography>
        </form>
      </Container>
    </Box>
  );
}

export default Login;
