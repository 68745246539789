

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import '@fontsource/roboto/400.css';
import $ from 'jquery';
import './App.css';
import './bootstrap-grid.min.css';
import Register from './components/Register';
import Login from './components/Login';
import Logout from './components/Logout';
import SendMoneyForm from './components/SendMoneyForm';
import Dashboard from './components/Dashboard';
import MainContent from './components/MainContent';
import Layout from './components/Layout';
import Receipt from './components/Receipt';
import LastTransactions from './components/LastTransactions';
import IncomingRemittence from './components/IncomingRemittence';
import CommissionReport from './components/CommissionReport';
import AddAgent from './components/AddAgent'; 
import CommissionRate from './components/CommissionRate'; 
import LimitChange from './components/LimitChange'; 
import Settings from './components/Settings'; 
function App() {
  console.log("App component rendered");

  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/logout" element={<Logout />} />

          <Route element={<Layout limit={1000} />}>
            <Route path="/dashboard" element={<Dashboard limit={1000} />} />
            <Route path="/send-money" element={<SendMoneyForm />} />
            <Route path="/receipt" element={<Receipt />} />
            <Route path="/last-transactions" element={<LastTransactions />} />
            <Route path="/incoming-remittance" element={<IncomingRemittence />} />
            <Route path="/commission-report" element={<CommissionReport />} />
            <Route path="account/add-agent" element={<AddAgent />} />
            <Route path="account/commission-rate" element={<CommissionRate />} />
            <Route path="account/update-agency-limit" element={<LimitChange />} />
            <Route path="account/settings" element={<Settings />} />
            {/* <Route path="/main-content" element={<MainContent />} /> */}
            
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;


